<script>
  import SenjaEmbed from '$lib/Senja/Embed.svelte';
</script>

<section id="testimonial" class="flex flex-col items-center py-[5%] px-3 lg:px-0">
  <h1
    class="mx-auto text-3xl md:text-4xl lg:text-6xl font-normal leading-[1.5] flex flex-col items-center gap-4"
  >
    Hear from Happy ClassroomIO Users
  </h1>

  <p
    class="mx-auto text-center my-5 lg:mt-6 px-4 lg:p-0 text-base tracking-wide leading-6 text-gray-400 lg:w-2/4"
  >
    See what excites them about building and managing bootcamps with ClassroomIO.
  </p>
  <a
    class="md:text-base w-11/12 rounded-md bg-blue-700 px-6 py-3 text-sm font-medium text-white text-center transition-all delay-100 hover:bg-blue-800 hover:shadow-xl hover:scale-95 md:w-fit lg:px-6 lg:py-5 lg:font-semibold"
    href="/signup"
    rel="noopener noreferrer nofollow"
  >
    Sign Up for Free
  </a>

  <div class="relative my-5 md:my-10 w-[85%] lg:w-[70%]">
    <SenjaEmbed id="3e847d7a-13d3-483e-8a71-5b35273dcc6c" />
  </div>
</section>
